import { FC, useEffect } from 'react';
import { ConnectedPagination } from '../../../../../../components/ConnectedPagination';
import { MemberRebatesTable } from '../../../../tableUtils/MemberRebatesTable';
import { getPaginatedData, Loader } from '../../../../../../utils';
import { SearchNotFound } from '../../../../../../components/SearchNotFound';
import '../../../../rebates.scss';
import '../../../../../../scss/styles.scss';

const gridHeader = [
  {
    label: 'Member',
    value: 'memberName',
    sortKey: 'memberName',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Member User Admin',
    value: 'firstName',
    sortKey: 'firstName',
    xs: 12,
    lg: 4,
  },
  {
    label: 'Current Available Rebates',
    value: 'currentAvailableRebates',
    sortKey: 'currentAvailableRebates',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Last Action Taken',
    value: 'lastActionTaken',
    sortKey: 'lastActionTaken',
    xs: 12,
    lg: 2,
  },
];

export const EarnedRebatesContent: FC<{
  getMemberRebatesQuarter: any;
  searchText: any;
  sortKey: any;
  setSortKey: any;
  sortType: any;
  paginationReducer: any;
  memberRebates: any;
  updateMemberRebatesInfo: any;
  updateMemberRebatesInfoDetails: any;
  quarter: string;
  year: string;
  onExportMemberRebatesByQuarterYear: any;
}> = ({
  getMemberRebatesQuarter,
  searchText,
  sortKey,
  setSortKey,
  sortType,
  paginationReducer: { rowsPerPage, page },
  memberRebates: {
    memberQuarterList,
    memberActiveQuarter,
    memberRebateList,
    memberRebatesListSpinner,
  },
  updateMemberRebatesInfo,
  updateMemberRebatesInfoDetails,
  quarter,
  year,
  onExportMemberRebatesByQuarterYear,
}) => {
  const isInprogressStatus =
    memberQuarterList &&
    memberQuarterList.filter((item: any) => item.status === 'INPROGRESS');

  useEffect(() => {
    const FIFTEEN_SEC = 5000;
    if (isInprogressStatus.length > 0) {
      const interval = setInterval(() => {
        getMemberRebatesQuarter();
      }, FIFTEEN_SEC);
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [isInprogressStatus]);

  const filteredData =
    searchText && memberRebateList.length > 0
      ? memberRebateList.filter((lineItem: any) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : memberRebateList;

      const sortedData = 
      sortKey && sortType && filteredData.length > 0
          ? filteredData.sort((a: any, b: any) => {
              
              if (sortKey === 'currentAvailableRebates') {
                  // Sort by currentAvailableRebates as a number
                  if (sortType === 'DESC') {
                      return parseInt(b.currentAvailableRebates) - parseInt(a.currentAvailableRebates);
                  } else {
                      return parseInt(a.currentAvailableRebates) - parseInt(b.currentAvailableRebates);
                  }
              } else {
                  // Sort other fields alphabetically
                  if (a[sortKey] && b[sortKey]) {
                      return sortType === 'DESC'
                          ? b[sortKey].localeCompare(a[sortKey])
                          : a[sortKey].localeCompare(b[sortKey]);
                  }
                  // Handle null or undefined values
                  return a[sortKey] ? -1 : 1;
              }
          })
          : filteredData;

  const modifiedList = getPaginatedData(sortedData, rowsPerPage, page);

  if (memberRebatesListSpinner) {
    return (
      <div className="relative-wrapper">
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      </div>
    );
  } else if (isInprogressStatus.length > 0) {
    return (
      <SearchNotFound
        sectionType={'Member Rebates List'}
        status={'INPROGRESS'}
      />
    );
  } else {
    return (
      <div className="table-wrapper">
        <MemberRebatesTable
          gridHeader={gridHeader}
          gridData={modifiedList ? modifiedList : []}
          setSortKey={setSortKey}
          sortType={sortType}
          memberActiveQuarter={memberActiveQuarter}
          updateMemberRebatesInfo={updateMemberRebatesInfo}
          updateMemberRebatesInfoDetails={updateMemberRebatesInfoDetails}
          quarter={quarter}
          year={year}
          onExportMemberRebatesByQuarterYear={onExportMemberRebatesByQuarterYear}
        />

        {filteredData.length > 0 && modifiedList?.length > 0 &&(
          <ConnectedPagination count={filteredData.length} />
        )} 
      </div>
    );
  }
};
